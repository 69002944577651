export default class FakeRecaptcha {
  constructor() {
    this.verifyCallbacks = []
    this.resetCallbacks = []
    this.renderCallbacks = []
    this.recaptcha = {
      reset: () => {},
    }
  }

  verify() {
    return new Promise((resolve, reject) => {
      const fakeToken = 'this-is-fake-recaptcha-token'
      this.verifyCallbacks.forEach(callback => {
        callback(fakeToken)
      })
      resolve(fakeToken)
    })
  }

  reset() {
    this.resetCallbacks.forEach(callback => {
      callback()
    })
  }

  render() {
    this.renderCallbacks.forEach(callback => {
      callback()
    })
  }

  addVerifyCallback(callback) {
    this.verifyCallbacks.push(callback)
  }

  addResetCallback(callback) {
    this.resetCallbacks.push(callback)
  }

  addRenderCallback(callback) {
    this.renderCallbacks.push(callback)
  }
}
