<template>
  <div class="auth-footer-btn d-flex justify-content-center my-2 mt-4">
    <a
      v-for="localeObj in getLanguagesList()"
      :key="localeObj.name"
      class="text-secondary"
    >
      <span
        v-if="getLocale() !== localeObj.slug"
        :class="`text-${localeObj.slug}`"
        @click="setLocale(localeObj.slug, $i18n)"
      >
        {{ localeObj.native }}
        <feather-icon icon="GlobeIcon" />
      </span>
    </a>
  </div>
</template>
<script>
import {
  getLanguagesList, getLocale,
} from '@core/utils/utils'
import { setLocale } from '@/libs/i18n'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    getLanguagesList,
    getLocale,
    setLocale,
  },
  setup(props, ctx) {
    return {
    }
  },

}
</script>
